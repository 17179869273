// src/data/portfolioItems.js
const portfolioItems = [
  {
    id: 1,
    title: 'Project Alpha',
    description: 'A revolutionary AI-powered analytics platform',
    startYear: 2022,
    releaseYear: 2023,
    client: 'TechCorp Inc.',
    rating: 5,
    languages: ['Python', 'JavaScript'],
    database: 'PostgreSQL',
    ui: 'React',
    images: [
      'https://picsum.photos/seed/alpha1/800/600',
      'https://picsum.photos/seed/alpha2/800/600',
      'https://picsum.photos/seed/alpha3/800/600',
    ],
    timeline: {
      rnd: 30,
      planning: 15,
      development: 120,
      testing: 45,
      launch: 10,
    },
    flow: 'Research AI Algorithms → Data Collection → Data Preprocessing → Develop Core Analytics Engine → Integrate with Frontend → Testing → Final Release'
  },
  {
    id: 2,
    title: 'Beta App',
    description: 'Mobile app for seamless task management',
    startYear: 2021,
    releaseYear: 2022,
    client: 'Productivity Solutions Ltd.',
    rating: 4,
    languages: ['Swift', 'Kotlin'],
    database: 'SQLite',
    ui: 'Native (iOS & Android)',
    images: [
      'https://picsum.photos/seed/beta1/800/600',
      'https://picsum.photos/seed/beta2/800/600',
      'https://picsum.photos/seed/beta3/800/600',
    ],
    timeline: {
      rnd: 20,
      planning: 25,
      development: 90,
      testing: 30,
      launch: 15,
    },
    flow: 'User Research → Prototyping → iOS Development → Android Development → Cloud Sync Integration → Beta Testing → App Store Launch → Play Store Launch'
  },
  {
    id: 3,
    title: 'Gamma Web',
    description: 'E-commerce platform for local artisans',
    startYear: 2020,
    releaseYear: 2021,
    client: 'Artisan Marketplace',
    rating: 4.5,
    languages: ['PHP', 'JavaScript'],
    database: 'MySQL',
    ui: 'Vue.js',
    images: [
      'https://picsum.photos/seed/gamma1/800/600',
      'https://picsum.photos/seed/gamma2/800/600',
      'https://picsum.photos/seed/gamma3/800/600',
    ],
    timeline: {
      rnd: 25,
      planning: 20,
      development: 100,
      testing: 40,
      launch: 20,
    },
    flow: 'Market Research → Design Mockups → Backend Development → Frontend Development → Payment Integration → User Testing → Launch'
  },
  {
    id: 4,
    title: 'Delta Dashboard',
    description: 'Real-time data visualization tool for businesses',
    startYear: 2021,
    releaseYear: 2023,
    client: 'Data Insights Co.',
    rating: 5,
    languages: ['JavaScript', 'TypeScript'],
    database: 'MongoDB',
    ui: 'Angular',
    images: [
      'https://picsum.photos/seed/delta1/800/600',
      'https://picsum.photos/seed/delta2/800/600',
      'https://picsum.photos/seed/delta3/800/600',
    ],
    timeline: {
      rnd: 35,
      planning: 10,
      development: 110,
      testing: 50,
      launch: 15,
    },
    flow: 'Requirement Gathering → Wireframing → Development → Data Integration → User Acceptance Testing → Deployment'
  },
  {
    id: 5,
    title: 'Epsilon CRM',
    description: 'Customer relationship management system for SMEs',
    startYear: 2019,
    releaseYear: 2022,
    client: 'SmallBiz Solutions',
    rating: 4,
    languages: ['Ruby', 'JavaScript'],
    database: 'PostgreSQL',
    ui: 'React',
    images: [
      'https://picsum.photos/seed/epsilon1/800/600',
      'https://picsum.photos/seed/epsilon2/800/600',
      'https://picsum.photos/seed/epsilon3/800/600',
    ],
    timeline: {
      rnd: 15,
      planning: 20,
      development: 95,
      testing: 35,
      launch: 10,
    },
    flow: 'Client Consultation → Feature Specification → Development → Testing → Training → Go Live'
  },
  {
    id: 6,
    title: 'Zeta Blog',
    description: 'A blogging platform with social media integration',
    startYear: 2020,
    releaseYear: 2021,
    client: 'Blogosphere',
    rating: 4.5,
    languages: ['JavaScript', 'Node.js'],
    database: 'Firebase',
    ui: 'Next.js',
    images: [
      'https://picsum.photos/seed/zeta1/800/600',
      'https://picsum.photos/seed/zeta2/800/600',
      'https://picsum.photos/seed/zeta3/800/600',
    ],
    timeline: {
      rnd: 20,
      planning: 15,
      development: 80,
      testing: 25,
      launch: 10,
    },
    flow: 'Concept Development → UI/UX Design → Development → Social Media Integration → Testing → Launch'
  },
  {
    id: 7,
    title: 'Eta Learning',
    description: 'Online learning management system for educational institutions',
    startYear: 2021,
    releaseYear: 2023,
    client: 'EduTech Inc.',
    rating: 5,
    languages: ['PHP', 'JavaScript'],
    database: 'MySQL',
    ui: 'Laravel + Vue.js',
    images: [
      'https://picsum.photos/seed/eta1/800/600',
      'https://picsum.photos/seed/eta2/800/600',
      'https://picsum.photos/seed/eta3/800/600',
    ],
    timeline: {
      rnd: 30,
      planning: 20,
      development: 120,
      testing: 40,
      launch: 15,
    },
    flow: 'Needs Analysis → Design → Development → Testing → Deployment → Feedback Collection'
  },
  {
    id: 8,
    title: 'Theta Fitness',
    description: 'Fitness tracking app with personalized workout plans',
    startYear: 2022,
    releaseYear: 2023,
    client: 'FitLife Co.',
    rating: 4.5,
    languages: ['Swift', 'JavaScript'],
    database: 'SQLite',
    ui: 'React Native',
    images: [
      'https://picsum.photos/seed/theta1/800/600',
      'https://picsum.photos/seed/theta2/800/600',
      'https://picsum.photos/seed/theta3/800/600',
    ],
    timeline: {
      rnd: 25,
      planning: 15,
      development: 100,
      testing: 30,
      launch: 10,
    },
    flow: 'User Research → Design → Development → Testing → Launch'
  },
  {
    id: 9,
    title: 'Iota Travel',
    description: 'Travel planning app with itinerary management',
    startYear: 2021,
    releaseYear: 2022,
    client: 'Wanderlust Inc.',
    rating: 4,
    languages: ['Java', 'JavaScript'],
    database: 'PostgreSQL',
    ui: 'Angular',
    images: [
      'https://picsum.photos/seed/iota1/800/600',
      'https://picsum.photos/seed/iota2/800/600',
      'https://picsum.photos/seed/iota3/800/600',
    ],
    timeline: {
      rnd: 20,
      planning: 20,
      development: 90,
      testing: 30,
      launch: 15,
    },
    flow: 'Market Research → Design → Development → Testing → Launch'
  },
  {
    id: 10,
    title: 'Kappa Finance',
    description: 'Personal finance management tool',
    startYear: 2020,
    releaseYear: 2021,
    client: 'FinancePro',
    rating: 4.5,
    languages: ['Python', 'JavaScript'],
    database: 'MongoDB',
    ui: 'Django + React',
    images: [
      'https://picsum.photos/seed/kappa1/800/600',
      'https://picsum.photos/seed/kappa2/800/600',
      'https://picsum.photos/seed/kappa3/800/600',
    ],
    timeline: {
      rnd: 15,
      planning: 15,
      development: 85,
      testing: 30,
      launch: 10,
    },
    flow: 'Requirement Gathering → Design → Development → Testing → Launch'
  },
  {
    id: 11,
    title: 'Lambda Social',
    description: 'Social media platform for niche communities',
    startYear: 2022,
    releaseYear: 2023,
    client: 'Community Connect',
    rating: 5,
    languages: ['Ruby on Rails', 'JavaScript'],
    database: 'PostgreSQL',
    ui: 'React',
    images: [
      'https://picsum.photos/seed/lambda1/800/600',
      'https://picsum.photos/seed/lambda2/800/600',
      'https://picsum.photos/seed/lambda3/800/600',
    ],
    timeline: {
      rnd: 40,
      planning: 20,
      development: 150,
      testing: 60,
      launch: 20,
    },
    flow: 'Community Research → Design → Development → Testing → Launch'
  },
];

export default portfolioItems;
